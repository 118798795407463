(function ($) {
  $(function () {

    // column mcb-column column_image wso-img-box dark one-second wso-img-box-no-crop wso-img-box-zoom
    // Selektujemo prvi logo po ID-u (prilagodite ID vašem slučaju)
    $('#logo1').on('click', function () {
      $(this).addClass('animated zoomOut');

      setTimeout(function () {
        showLoadingScreen();

        let dots = $('.dot');

        dots.each(function (index) {
          let dot = $(this);
          setTimeout(function () {
            dot.animate({
              opacity: (index + 1) * 0.2
            }, 1000);
          }, index * 1000);
        }); 
 

        setTimeout(function () {
          hideLoadingScreen(); 
          // window.location.href = 'http://localhost/physiotherapiezentrum-laimer/startseite';
          // window.location.href = 'https://iq-website.com/physiotherapie-laimer/startseite';
          window.location.href = 'https://www.physiotherapie-laimer.com/startseite';
        }, 5000);
      }, 500);
    });  
 

    function showLoadingScreen() {
      // Prikazujemo overlay
      $('#startesite').hide();
      $('#loading-overlay .loading-content').addClass('animated zoomIn');
      $('#loading-overlay').show();
    }

    function hideLoadingScreen() {
      // Sakrivamo overlay
      $('#startesite').hide();
      $('#loading-overlay').hide();
    }

    // Otvori/zatvori meni i dodaj/ukloni overlay
    $('#nav-icon').on('click', function () {
      $('.mobile-menu').toggleClass('open');
      $(this).toggleClass('open');

      if ($(this).hasClass('open')) {
        // Ako je meni otvoren, dodaj overlay
        $('.overlay').fadeIn();
      } else {
        // Ako je meni zatvoren, ukloni overlay
        $('.overlay').fadeOut();
      }

      // Toggle icon between menu and close
      if ($(this).hasClass('open')) {
        $(this).find('i').removeClass('icon-menu').addClass('icon-close');
      } else {
        $(this).find('i').removeClass('icon-close').addClass('icon-menu');
      }
    });

    // Zatvori meni klikom na overlay
    $('.overlay').on('click', function () {
      $('.mobile-menu').removeClass('open');
      $('#nav-icon').removeClass('open');
      $('#nav-icon i').removeClass('icon-close').addClass('icon-menu');
      $(this).fadeOut();
    });


    /*
     * Navbar fixed 
     */
    // $(window).on('scroll', function () {
    //   if ($(this).scrollTop() > 1) {
    //     $('.site-header').addClass('navbar-fixed-top');
    //   } else {
    //     $('.site-header').removeClass('navbar-fixed-top');
    //   }
    // });

    /* 
     * Owl carousel
     */
    $('.owl-home').owlCarousel({
      margin: 20,
      items: 3,
      loop: true,
      autoplay: true,
      autoplayTimeout: 4500,
      nav: false,
      dots: false,
      animateOut: 'fadeOut',
      // navText: ["<i class='dr-arrow-left'></i>", "<i class='dr-arrow-right'></i>"],
      // navContainer: '#customNav',
      responsive: {
        0: {
          items: 1
        },
        700: {
          items: 2,
          margin: 0,
        },
        1200: {
          items: 3,
        }
      }
    });



    /*
     * Video modal 
     */
    $("body").on("click", "#play-video-modal", function (e) {
      e.preventDefault();
      $("#video-modal").modal("show");
      $("#video-modal iframe")[0].src += "?autoplay=1";
    });

    $("#video-modal").on('hidden.bs.modal', function (e) {
      var videoUrl = $("#video-modal iframe")[0].src;
      videoUrl = videoUrl.replace("?autoplay=1", "");
      $("#video-modal iframe")[0].src = videoUrl;
    });

    /*
     * Open modal
     */

    $('#open-modal').on('click', function (e) {
      e.preventDefault();
      $('#modal').modal('show');
    });

    /*
     * Youtube lazy loading
     */
    $('#play-video-btn').on('click', function (e) {
      e.preventDefault();
      $('#youtubeModal').modal('show');
    });

    $("#youtubeModal").on('hidden.bs.modal', function (e) {
      $("#youtubeModal iframe").attr("src", $("#youtubeModal iframe").attr("src"));
    });



    $('#play-video-btn').on('click', function () {
      var tag = document.createElement('script');
      tag.src = "https://www.youtube.com/iframe_api";
      document.body.appendChild(tag);
    })

    window.onYouTubeIframeAPIReady = function () {
      var videoModules = document.querySelectorAll('.video');
      videoModules = Array.prototype.slice.call(videoModules);
      videoModules.forEach(initializeVideoModule);
    }

    function initializeVideoModule(videoModule) {
      var player = new YT.Player(videoModule.querySelector('.video-placeholder'), {
        videoId: videoModule.dataset.videoId,
        events: {
          onStateChange: function (event) {
            var isEnded = event.data === YT.PlayerState.ENDED;
            videoModule.classList[isEnded ? 'remove' : 'add']('playing');
            if (isEnded) {
              player.destroy();
              videoModule.querySelector('.video-layer').innerHTML = (
                '<div class="video-placeholder"></div>'
              );
              initializeVideoModule(videoModule);
            }
          }
        }
      });
    }

    /*
     * Scroll up button
     */
    $(window).scroll(function () {
      if ($(this).scrollTop() > 150) {
        $('.scroll-to-top').addClass('show');
      } else {
        $('.scroll-to-top').removeClass('show');
        $('.scroll-to-top').addClass('animated bounceOutDown');
        $('.scroll-to-top').removeClass('animated bounceOutUp');
        $('.scroll-to-top i').css('transform', 'rotateY(0) scale(1)');
      }
    });

    $('.scroll-to-top').on("click", function () {
      $('.scroll-to-top').addClass('animated bounceOutUp');
      $('.scroll-to-top i').css('transform', 'rotateY(180deg) scale(1.2)');
      $("html, body").animate({
        scrollTop: 0
      }, 1000);
      return false;
    });

    /*
     * Lightbox gallery
     */
    $('.gallery-images').lightGallery({
      selector: '.gallery-item',
      share: true,
      download: true,
      rotate: false,
      showThumbByDefault: true,
      loop: false,
      slideEndAnimatoin: false,
      thumbnail: true,
      allowMediaOverlap: true,
      toggleThumb: true,
      youtubePlayerParams: {
        loadYoutubeThumbnail: true,
        youtubeThumbSize: 'default',
        modestbranding: 1,
        showinfo: 0,
        rel: 0
      }
    });



  });
})(jQuery);